import * as React from "react"
import {graphql} from "gatsby";
import error from '../../src/images/error.png';
import styled from 'styled-components';
import {Trans, useI18next, Link} from 'gatsby-plugin-react-i18next';
import {H1} from "../components/UsableComponents/H1";
import {theme} from "../utils/theme";
import {DropdownToggle} from "../components/UsableComponents/DropdownToggle";
import {DropdownMenu} from "../components/UsableComponents/DropdownMenu";
import {DropdownItem} from "../components/UsableComponents/DropdownItem";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../images/logo.png";
import {Content} from "../components/UsableComponents/Content";
import {H2} from "../components/UsableComponents/H2";
import {Button} from "../components/UsableComponents/Button";
import {Helmet} from "react-helmet";

const StyledContent = styled(Content)`
    position: relative;
    
    ${theme.media.phoneSmall} {
        padding: 40px 0 30px;
    }
    
    ${theme.media.tabletSmall} {
        padding: 30px 0;
    }
    
    ${theme.media.tabletLarge} {
        padding: 50px 0;
    }
`;

const StyledH1 = styled(H1)`
    ${theme.media.phoneSmall} {
        width: 90%;
        margin: 0 auto 50px;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
    
    ${theme.media.desktopLarge} {
        margin: 0 auto 80px;
    }
`;

const ErrorContainer = styled.div`
    margin: 0 auto;
    text-align: center;

    ${theme.media.phoneSmall} {
        width: 90%;
    }
    
    ${theme.media.desktop} {
        width: 85%;
    }
`;

const StyledImg = styled.img`
    ${theme.media.phoneSmall} {
        width: 260px;
    }
    
    ${theme.media.desktop} {
        width: 400px;
    }
    
    ${theme.media.desktopLarge} {
        width: 550px;
    }
`;

const StyledDropdown = styled(Dropdown)`
    position: absolute;

    :after {
        content: "";
        border: solid ${theme.colors.orange1};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        position: absolute;
        right: 10px;
        pointer-events: none;
    }
    
    ${theme.media.phoneSmall} {
        top: 10px;
        right: 15px;
        
        :after {
            top: 7px;
        }
    }
    
    ${theme.media.tabletSmall} {
        right: -35px;
    }
    
    ${theme.media.tabletLarge} {
        top: 20px;
        right: -10px;
        
        :after {
            top: 12px;
        }
    }
    
    ${theme.media.desktop} {
        top: 60px;
        right: -30px;
    }
`;

const KangaLogo = styled.img`
    position: absolute;
    top: 35px;
    
    ${theme.media.phoneSmall} {
        display: none;
    }
    
    ${theme.media.desktopLarge} {
        display: block;
        left: -180px;
    }
`;

const StyledH2 = styled(H2)`
    ${theme.media.phoneSmall} {
        font-size: 18px;
        margin: 30px; 0 20px;
    }
    
    ${theme.media.tabletSmall} {
        font-size: 20px;
    }
    
    ${theme.media.tabletLarge} {
        margin: 50px 0 50px;
    }
    
    ${theme.media.desktop} {
        margin: 50px 0;
        font-size: 24px;
    }
    
    ${theme.media.desktopLarge} {
        margin: 80px 0 50px;
        font-size: 24px;
    }
`;

const StyledButton = styled(Button)`
    ${theme.media.phoneLarge} {
        width: 50%
    }
    
    ${theme.media.tabletSmall} {
        width: 30%;
    }
`;

const NotFoundPage = () => {

    const {languages, changeLanguage} = useI18next();

    const settingCurrentLng = () => {
        const isBrowser = () => typeof window !== "undefined";
        if (isBrowser() && window.location.href.includes("en")) {
            return "en";
        } else if (isBrowser() && window.location.href.includes("uk")) {
            return "uk";
        } else {
            return "pl";
        }
    };

    return (
        <>
            <Helmet>
                <title>Bon Kanga Kantor</title>
            </Helmet>
            <StyledContent>
                <StyledH1><Trans>Sprzedaj kryptowalutę w kantorze</Trans></StyledH1>
                <StyledDropdown>
                    <DropdownToggle variant="success" id="dropdown-basic">
                        {settingCurrentLng()}
                    </DropdownToggle>

                    <DropdownMenu>
                        {languages.map(lng =>
                            <DropdownItem key={lng} onClick={(e) => {
                                e.preventDefault();
                                changeLanguage(lng);
                            }}>{lng}</DropdownItem>
                        )}
                    </DropdownMenu>
                </StyledDropdown>
                <a href="/"><KangaLogo src={logo}/></a>

                <ErrorContainer>
                    <StyledImg src={error}/>
                </ErrorContainer>
                <StyledH2><Trans>Nie znaleziono strony!</Trans></StyledH2>
                <StyledButton as={Link} to="/"><Trans>Strona główna</Trans></StyledButton>
            </StyledContent>
        </>
    )
};

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
